import React from 'react'

import ContactForm from './ContactForm'

import styles from './InquiryLink.module.styl'

export default class InquiryLink extends React.Component {

  constructor(props) {
    super(props)
    this.state = { opened: false }
  }

  open = (ev) => {
    this.setState({ opened: true });
  }

  render() {
    if(!this.state.opened) {
      return (
        <a className={ styles.inquiryLink } onClick={ this.open }>
          or send an inquiry
        </a>
      )
    } else {
      return (
        <div className={ styles.inquiryLinkForm }>
          <div>Let us know how we can help you:</div>
          <ContactForm bare={ true } />
        </div>
      )
    }
  }
}


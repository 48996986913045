import React from 'react'

import { Link } from 'gatsby'

import styles from './ServicesArticles.module.styl'

export default class ServicesArticles extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={ styles.articles }>
        <h2>Articles</h2>
        {this.props.articles.map(({ node }) => {
          let slug = node.fields.slug.replace(/\/$/, "")
          return (
            <article key={ slug }>
              <Link to={ slug }>
                <span className={ styles.category }>
                  {node.frontmatter.category}
                </span>
                <span className={ styles.title }>
                  {node.frontmatter.title}
                </span>
                <span className={ styles.kicker }>
                  {node.frontmatter.kicker}
                </span>
                <span className={ styles.intro }>
                  {node.frontmatter.intro}
                </span>
                <span className={ styles.author }>
                  Written by {node.frontmatter.author}
                </span>
              </Link>
            </article>
          )
        })}
      </div>
    )
  }
}


/* Services: data foundations
 *
 */

import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Calendly from '../../components/Calendly'
import InquiryLink from '../../components/InquiryLink'
import ServicesArticles from '../../components/ServicesArticles'

import pyramid from './pyramid.png'
import styles from './data-foundations.module.styl'

const title = 'FELD Berlin. We can help with your data foundations'
const description = `
  Data scientist, BI analyst and all your other teams use data. And yes, all of
  these teams also produce data. So, systems get built, data is produced – and
  before you know it, you’ve landed in a bit of a mess: you have silos that are
  hard to access, spaghetti ETLs that shovel data from one place to the next,
  and nobody can answer where a piece of data came from, what guarantees are
  attached to it, or if it’s even correct. As the system grows, things begin to
  grind to a halt. What now? <span>We can work on your data foundations.</span>
`

export default function DataFoundations(props) {
  const posts = props.data.allMdx.edges

  return (
    <Layout location={ props.location } footer={ "newsletter" }>
      <SEO title={ title } description={ description } />
      <div className={ styles.dataFoundations }>
        <h1>Data Foundations</h1>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <img src={ pyramid } />
        <div className={ styles.service }>
          <h2>Cloud Maturity</h2>
          <p>
            Whether you’re on AWS, GCP or Azure, you’ll need a solutions
            architect to design and build the right cloud infrastructure to support
            your data work. You’ll need an identity and access management for data,
            and components to handle data submission, ingest processing, dataset
            management and data transformation. This is your engineering foundation.
          </p>
        </div>
        <div className={ styles.service }>
          <h2>Datasets</h2>
          <p>
            Your data should be organised. Datsets should be owned and published with
            a schema and data dictionary, and the owning team should ensure for
            correctness. The privacy properties and lineage should be easily
            discovered. Software engineering principles should be applied to your data
            and ETLs – testing, code review and continuous delivery should be applied.
          </p>
        </div>
        <div className={ styles.service }>
          <h2>MLOps and Testing</h2>
          <p>
            Organisations that use machine learning in their products need to rapidly
            train and test. Offline testing and online testing needs to be conducted
            and evaluated in notebook for communication to stakeholders. Explorations
            need to be hosted for team collaboration without getting into a tangle of
            ad hoc artifacts, and all results should be reproducible at any time.
          </p>
        </div>
        <div className={ styles.service }>
          <h2>ML and Data Science</h2>
          <p>
            Once the foundations have been laid, your teams can work effectively with
            data and models. Your models don’t have to be complicated to benefit from
            solid data foundations. Many of the topics described here can be
            introduced slowly, and can improve the work of your teams one piece at
            a time.
          </p>
        </div>
        <div className={ styles.footer }>
          <div className={ styles.callToAction }>
            <div className={ styles.questions }>
              We can help with your <br />
              data foundations
            </div>
            <Calendly text="Schedule a call"/>
            <InquiryLink />
          </div>
          <div className={ styles.divider } />
          <ServicesArticles articles={ posts } />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { category: { eq: "data foundations" },
      published: { eq: true }}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            author
            kicker
            intro
            category
          }
        }
      }
    }
  }
`
